<template>
  <div>
    <div class="title">物流信息</div>
    <div style="padding: 0 20px;margin-bottom: 20px;">物流编号: {{ logisticsInfo.delivery_no }}</div>
    <div style="padding: 0 20px;">
      <div v-for="(item, index) in logisticsInfo.data.Traces" :key="index" style="margin-bottom: 10px;">
        <div class="time">
          {{ item.AcceptTime }}
        </div>
        <div>
          {{ item.AcceptStation }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, onMounted } from 'vue'
import axios from '@/libs/axios'
import { useRoute } from 'vue-router'

const route = useRoute()
const logisticsInfo = reactive({
  delivery_no: 0,
  logist_name: '',
  data: {}
})

const getLogisticsCode = (logist_name = '') => {
  let code = null
  const logisticsArr = [
    { name: '顺丰', code: 'SF' },
    { name: '百世', code: 'HTKY' },
    { name: '中通', code: 'ZTO' },
    { name: '申通', code: 'STO' },
    { name: '圆通', code: 'YTO' },
    { name: '韵达', code: 'YD' },
    { name: '邮政', code: 'YZPY' },
    { name: 'EMS', code: 'EMS' },
    { name: '京东', code: 'JD' },
    { name: '优速', code: 'UC' },
    { name: '德邦', code: 'DBL' },
    { name: '极兔', code: 'JTSD' },
    { name: '众邮', code: 'ZYE' },
    { name: '宅急送', code: 'ZJS' }
  ]
  if (!logist_name) {
    return code
  }
  logisticsArr.forEach(item => {
    if (logist_name.search(item.name) != -1) {
      code = item.code
    }
  })
  return code
}

const getLogistics = async () => {
  const response = await axios.post('/orders/logistics', {
    delivery_code: getLogisticsCode(logisticsInfo.logist_name),
    delivery_no: logisticsInfo.delivery_no
  })
  logisticsInfo.data = response
}

onMounted(() => {
  logisticsInfo.delivery_no = route.query.delivery_no
  logisticsInfo.logist_name = route.query.logist_name
  getLogistics()
})
</script>
<style lang="less" scoped>
.title {
  margin: 20px 0;
  padding: 0 20px;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: #3aa20f;
    display: inline-block;
  }
}

.time {
  margin: 10px 0;
  padding: 0 10px;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    left: 0;
    top: 6px;
    bottom: 0;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #3aa20f;
    display: inline-block;
  }
}
</style>
